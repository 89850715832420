import { getAppInfo, getDeviceInfo } from "@src/appV2/lib";
import { setReferralRate } from "@store/session/actions";
import { isEmpty } from "lodash";
import { FC, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateAgentVersion } from "./api";

const WorkerAppVersion: FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const updateVersionInfo = async () => {
      dispatch(setReferralRate());
      const { version, build, otaBuildId } = await getAppInfo();
      if (isEmpty<String>(version) || isEmpty<String>(build)) {
        return;
      }
      const { platform } = await getDeviceInfo();

      await updateAgentVersion({
        platform,
        version: version,
        build: Number(build),
        otaBuildId,
      });
    };

    updateVersionInfo();
  }, [dispatch]);

  return <Fragment />;
};

export { WorkerAppVersion };
