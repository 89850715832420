import { getFirebaseSingleton } from "@lib/firebase";
import { onLoggedOut } from "@src/app/store/session";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const useFirebaseListener = (pathname: string, email: string) => {
  const dispatch = useDispatch();

  const [isAuthenticated, setAuthenticated] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (pathname === "/welcome/login/emailVerify" && !email) {
      localStorage.setItem("isInitAutoLogin", "true");
    }

    getFirebaseSingleton().onIdTokenChanged(async (user) => {
      const isInitAutoLogin = localStorage.getItem("isInitAutoLogin");
      localStorage.removeItem("isInitAutoLogin");

      if (
        isInitAutoLogin === "true" &&
        user &&
        pathname === "/welcome/login/emailVerify" &&
        !email
      ) {
        // this will return in signin page, and not automatically login
        await getFirebaseSingleton().signOut();
        indexedDB.deleteDatabase("firebaseLocalStorage");
        return;
      }

      setAuthenticated(Boolean(user));

      if (user) {
        logEvent(APP_V2_APP_EVENTS.SIGNED_IN_TO_FIREBASE);

        await queryClient.invalidateQueries([GET_WORKER_PATH]);
      } else {
        setTimeout(async () => {
          logEvent(APP_V2_APP_EVENTS.SIGNED_OUT_FROM_FIREBASE);
          setAuthenticated(false);
          // clear worker stored in api hook
          await queryClient.cancelQueries([GET_WORKER_PATH]);
          queryClient.removeQueries([GET_WORKER_PATH]);
          onLoggedOut(dispatch);
        }, 1000);
      }
    });
    /**
     * This function should run once, but it depends on pathname and an email in the queryString.
     * We don't want to execute it for every path change, that's why ignoring rule-of-hooks here.
     * I believe we should refactor to not use pathname, but rather `window.location`
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated;
};
