import { App } from "@capacitor/app";
import { toError } from "@clipboard-health/util-ts";
import { getFirebaseSingleton } from "@lib/firebase";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

export const useAppStateListener = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const result = App.addListener("appStateChange", async ({ isActive }) => {
      if (!isActive) {
        return;
      }

      const firebaseUser = getFirebaseSingleton().currentUser;
      if (firebaseUser) {
        await firebaseUser.getIdToken();
      }

      await queryClient.invalidateQueries([GET_WORKER_PATH]);

      logEvent(APP_V2_APP_EVENTS.APP_RESTORED, {
        isAuthorizedInFirebase: Boolean(firebaseUser),
      });
    }).catch((maybeError: unknown) => {
      const error = toError(maybeError);

      logError(APP_V2_APP_EVENTS.FAILED_TO_SET_UP_APP_STATE_LISTENER, {
        error,
      });
    });

    return () => {
      result?.then((listener) => listener?.remove());
    };
  }, [queryClient]);
};
