import { Preferences } from "@capacitor/preferences";
import { isDefined, toError } from "@clipboard-health/util-ts";
import { getFirebaseSingleton } from "@lib/firebase";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { EMPTY_STRING_WORKER_ERROR, useGetWorker } from "@src/appV2/Worker/api/useGetWorker";
import {
  ActionType,
  LocalStorage,
  getUserByEmail,
  identifyAgent,
  loginZendesk,
  saveAgentStageandMSAInfo,
  setEnvVariables,
  showAgentProfileFailedToLoadError,
} from "@store/session";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useFetchAndInitializeWorker = (
  isAuthenticatedInFirebase: boolean,
  isAuthorized: boolean
) => {
  const dispatch = useDispatch();

  const {
    data: agent,
    error,
    isLoading,
  } = useGetWorker({
    enabled: isAuthenticatedInFirebase,
  });

  useEffect(() => {
    if (!isDefined(error)) {
      return;
    }

    if (toError(error).message !== EMPTY_STRING_WORKER_ERROR) {
      // This controls AgentProfileErrorModal component, which is surprisingly only rendered in onboarding
      // We should add some more unique error screen for all type of views
      showAgentProfileFailedToLoadError(dispatch);
    }
  }, [dispatch, error]);

  useEffect(() => {
    (async () => {
      const user = getFirebaseSingleton().currentUser;
      // we don't wanna run this hook if agent is still fetching because it might cause race conditions of user id being set before
      // agent profile (when getUserByEmail finishes before actual agent fetch)
      if (!user || isLoading) {
        return;
      }

      try {
        if (agent) {
          logEvent(APP_V2_APP_EVENTS.GET_WORKER_SUCCESS);

          dispatch({
            type: ActionType.SHOW_FETCH_WORKER_FAILURE_ALERT,
            data: {
              isFetchAgentProfileFailureAlertEnabled: false,
            },
          });

          await Preferences.set({
            key: LocalStorage.AGENT,
            value: JSON.stringify(agent),
          });

          void identifyAgent(dispatch, agent);
        }

        if (!isAuthorized) {
          const userAgent = await getUserByEmail();
          const { agent } = userAgent || {};

          if (userAgent?.tmz) {
            moment.tz.setDefault(userAgent.tmz);
          }

          dispatch({
            type: ActionType.LOGGED_IN,
            data: {
              userId: userAgent?._id,
              sendBirdAccessToken: userAgent?.sendBirdAccessToken,
              profile: {
                name: agent?.name,
                email: agent?.email,
                phone: agent?.phone ?? user?.phoneNumber,
              },
            },
          });

          logEvent(APP_V2_APP_EVENTS.AUTHORIZED_WORKER, {
            userId: userAgent?._id,
            email: agent?.email,
            phone: agent?.phone ?? user?.phoneNumber,
            name: agent?.name,
          });
        }

        saveAgentStageandMSAInfo(agent);

        await Promise.allSettled([loginZendesk(), setEnvVariables(dispatch)]);
      } catch (error) {
        logError(APP_V2_APP_EVENTS.WORKER_INITIALIZATION_FAILURE, {
          error,
          metadata: {
            phone: user.phoneNumber,
            email: user.email,
            name: user.displayName,
            uid: user.uid,
          },
        });
      }
    })();
  }, [dispatch, agent, isAuthorized, isAuthenticatedInFirebase, isLoading]);
};
