import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import request from "superagent";

import { AgentVersionRequest, VersionInfoResponse, VersionUpdateOptions } from "./model";
import { getAuthHeader } from "../superagent";

export const getMobileAppVersionsPostLogin = async (
  appVersion: string,
  platform
): Promise<VersionInfoResponse> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/version/mobile_app_with_auth`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .query({ appVersion, platform })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const getMobileAppVersionsPreLogin = (
  options: VersionUpdateOptions
): Promise<VersionInfoResponse> => {
  return request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/version/mobile_app_without_auth`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .query({ ...options })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const updateAgentVersion = async (req: AgentVersionRequest): Promise<void> => {
  return await request
    .post(`${environmentConfig.REACT_APP_WORKER_SERVICE_URL}/agentprofile/version`)
    .set(await getAuthHeader())
    .send(req)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { updateAgentVersion };
